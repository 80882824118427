/* eslint no-lone-blocks: 0 */
import * as React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { EventOrValueHandler, Field, FieldArray, WrappedFieldProps } from 'redux-form';
import { Trans, useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import { Select, AsyncSelect } from '@common/components/form/inputs/select';
import { AsyncTable } from '../table';
import Icon, { IconTypes } from '../icon';
import { Button, ButtonType } from '../button';
import Modal from '../modal';
import Label from '../form/label';
import Dropdown from '../dropdown';
import SearchBar from '../search-bar';
import List from '../list';
import ProfileImage from '../profile-image';

import { PredicateSelectorMultiSelect } from './filter-multi-select';
import { PredicateSelectorAsyncMultiSelect } from './filter-async-multi-select';
import { PredicateSelectorDate } from './filter-date';
import { PredicateSelectorNumber } from './filter-number';

import * as organisationSelector from '../../../modules/organisation/selectors/organisation';

import Api from '../../services/api';
import createTempId from '../../utils/create-temp-id';
import mergeNames from '../../utils/merged-names-text';
import type { ListApiResponse } from '@common/types/util-types';
import { predicatesToPayload } from '@common/utils/predicates';

import type { SimpleUser, Organisation, Network, OrganisationFunction } from '../../types/objects';
import type { StoreState } from '@common/types/store';
import type { Predicate } from '@common/types/objects';
import { ESearchPredicateTypes, EPredicateOperators, EPredicateFields } from '@common/definitions';

// Display user result
export const PredicatesUserItem = ({ item: user, maxShownItems = 3 }: { item: SimpleUser, maxShownItems: number }) => [
  <ProfileImage key="profile_img" size={35} user={user} />,
  <span key="full_name" className="fs-exclude">{user.full_name}</span>,
  // @ts-expect-error
  <span key="networks">{mergeNames(user.networks, maxShownItems, 'name', undefined, '+')}</span>,
  // @ts-expect-error
  <span key="functions">{mergeNames(user.functions, maxShownItems, 'name', undefined, '+')}</span>,
];

type ItemProps = {
  item: Record<string, unknown>,
  labelKey: string,
  valueKey: string,
  defaultText: string,
  renderRow?: ({ item }: { item: Record<string, unknown> }) => React.ReactNode,
  onRemove: (value: unknown) => void,
};

// Item in predicate filter
const MultiInputItem = React.memo<ItemProps>((props: ItemProps) => {
  const {
    item,
    labelKey,
    valueKey,
    defaultText,
    renderRow,
    onRemove,
    ...rest
  } = props;
  return (
    <div className="Form__MultiInput__Item">
      {renderRow ? renderRow({ item, ...rest }) : item[labelKey]}
      {
        item.default ?
          <small>{defaultText}</small> :
          <Button
            icon="delete"
            size="large"
            onClick={() => onRemove(item[valueKey])}
          />
      }
    </div>
  );
});

type Option = Record<string, unknown>;

type MultiInputValue = Array<{ value: any, label: string }>;

type MultiInputOwnProps = {
  value: MultiInputValue;
  options?: Option[];
  labelKey?: string;
  valueKey?: string;
  defaultSelected?: Option[];
  searchPlaceholder?: string;
  defaultText?: string;
  className?: string;
  placeholder?: string;
  getOptions?: (value: string) => Promise<Option[]>;
  renderRow?: ({ item }: { item: Option }) => React.ReactNode;
  disabled?: boolean;
  onChange: EventOrValueHandler<React.ChangeEvent<MultiInputValue>>;
};

export const MultiInput = (props: MultiInputOwnProps) => {
  const {
    value,
    onChange,
    options,
    defaultSelected,
    getOptions,
    renderRow,
    placeholder,
    valueKey,
    className,
    labelKey,
    disabled = false,
    ...rest
  } = props;

  const selected = defaultSelected
    ? [...defaultSelected.map(R.assoc('default', true)), ...value]
    : value;

  // Fetch data async or data is already loaded
  const Selector = getOptions ? AsyncSelect : Select;

  // If item is already selected, disable it in the selector
  const disableNonAvailableOptions: (items: Option[]) => Option[] = R.map((item) => {
    // @ts-expect-error
    const find = R.find(R.propEq(valueKey, item[valueKey]), selected.filter((o) => !!o));

    if (find) return R.assoc('disabled', true, item);

    return item;
  });

  const addToFilter = (option: Option) => onChange(R.append(option, value));
  // @ts-expect-error
  const removeFromFilter = (id: string) => onChange(R.reject(R.propEq(valueKey, id), value));

  return (
    <div className={`Form__MultiInput${className ? ` ${className}` : ''}`}>
      <Selector
        searchable
        options={options && disableNonAvailableOptions(options)}
        getOptions={getOptions && ((searchQuery) => getOptions(searchQuery)
          .then(disableNonAvailableOptions))}
        labelKey={props.labelKey}
        valueKey={props.valueKey}
        placeholder={props.searchPlaceholder}
        onChange={addToFilter}
        disabled={disabled}
      />
      <List
        // @ts-expect-error
        items={selected || []}
        renderRow={MultiInputItem}
        rowProps={{
          key: valueKey,
          renderRow,
          valueKey,
          labelKey,
          defaultText: props.defaultText,
          onRemove: removeFromFilter,
          ...rest
        }}
        placeholder={placeholder && <small>{placeholder}</small>}
      />
    </div>
  );
};

export type PredicateFilter = {
  value: EPredicateFields;
  defaultOperator: EPredicateOperators;
  defaultValue: unknown[];
  icon: string;
  context: string;
  category: string;
  optionsKey?: string;
  valueKey?: string;
  labelKey?: string;
  selector: any;
  previewer: PredicatePreviewerFunc;
};

export type PredicateSelectFunc<Config = {}> = (
  predicate: Predicate,
  onChange: (newPredicate: Partial<Predicate>) => void,
  config: Config,
) => React.ReactNode;

export type PredicatePreviewerFunc<T extends {} = {}> = (
  value: any,
  filter: T,
  options?: Option[] | undefined,
) => React.ReactNode;

export const optionsPreviewer: PredicatePreviewerFunc<{ labelKey: string, valueKey: string }> = (
  { value },
  { labelKey = 'name', valueKey = 'id' },
  options,
) => {
  if (value.length === 0) {
    return (
      <span>
        <Trans i18nKey="common:predicates_filter_in" />
        {' '}
        <Trans i18nKey="common:predicates_filter_any" />
      </span>
    );
  }

  // @ts-expect-error
  const items = value.map((id: string) => R.find(R.propEq(valueKey, id), options)).filter((option: Option) => !!option);
  const first = items.shift();

  // If there are items but they can't be found in the data
  if (!first) return;

  return (
    <span>
      <Trans i18nKey="common:predicates_filter_in" />
      {' '}
      {first[labelKey]}
      {items.length > 0 ? ` +${items.length}` : ''}
    </span>
  );
};

export const numberPreviewer: PredicatePreviewerFunc = ({ comparison, value }) => (
  <span>
    <Trans
      i18nKey="common:predicates_filter_comparison"
      values={{
        context: comparison,
        value: value[0] !== undefined ? value[0] : value,
        otherValue: value[1],
      }}
    />
  </span>
);

export const datePreviewer: PredicatePreviewerFunc = ({ value }) => {
  const [from, to] = value;
  if (from === null || to === null) {
    return (
      <span>
        <Trans i18nKey="common:predicates_filter_in" />
        {' '}
        <Trans i18nKey="common:predicates_filter_any" />
      </span>
    );
  }

  return (
    <span>
      <Trans
        i18nKey="common:predicates_filter_comparison"
        values={{
          context: 'between',
          value: moment(value[0]).format('DD-MM-YYYY'),
          otherValue: moment(value[1]).format('DD-MM-YYYY'),
        }}
      />
    </span>
  );
};

export const predicateTypes = [
  ESearchPredicateTypes.MATCH_ALL,
  ESearchPredicateTypes.MATCH_ANY,
];

export const EPredicateFilters: Record<string, any> = {
  [EPredicateFields.USER]: {
    value: EPredicateFields.USER,
    defaultOperator: EPredicateOperators.IN,
    defaultValue: [],
    icon: 'person',
    context: 'user_name',
    category: 'employee',
    selector: PredicateSelectorAsyncMultiSelect,
    previewer: optionsPreviewer,
    getOptions: (organisationId: string, search: string) => {
      if (!search) return [];

      const query = Api.utils.toQuery({ q: search, limit: 25 });

      return Api.get(`/v2/organisations/${organisationId}/users?${query}`)
        .then((result) => result.data);
    },
    getData: (value: any, state: any, searchResult: any) => {
      return value.map((userId: string) => {
        return (
          state.users.items[userId] ||
          searchResult?.data?.find((user: any) => user?.id === userId)
        );
      });
    },
    labelKey: 'full_name',
  },
  [EPredicateFields.LANGUAGE]: {
    value: EPredicateFields.LANGUAGE,
    defaultOperator: EPredicateOperators.IN,
    defaultValue: [],
    icon: 'language',
    context: 'language',
    category: 'employee',
    selector: PredicateSelectorMultiSelect,
    previewer: optionsPreviewer,
    optionsKey: 'languageLocales',
    valueKey: 'locale',
    labelKey: 'name_eng',
  },
  [EPredicateFields.DAYS_IN_SERVICE]: {
    value: EPredicateFields.DAYS_IN_SERVICE,
    defaultOperator: EPredicateOperators.EQ,
    defaultValue: 0,
    icon: 'calendar_today',
    context: 'days_in_service',
    category: 'employee',
    selector: PredicateSelectorNumber,
    previewer: numberPreviewer,
  },
  [EPredicateFields.DAYS_BEFORE_OUT_OF_SERVICE]: {
    value: EPredicateFields.DAYS_BEFORE_OUT_OF_SERVICE,
    defaultOperator: EPredicateOperators.EQ,
    defaultValue: 0,
    icon: 'event_busy',
    context: 'days_before_out_of_service',
    category: 'employee',
    selector: PredicateSelectorNumber,
    previewer: numberPreviewer,
  },
  [EPredicateFields.NETWORK]: {
    value: EPredicateFields.NETWORK,
    defaultOperator: EPredicateOperators.IN,
    defaultValue: [],
    icon: 'business',
    context: 'network',
    category: 'company',
    selector: PredicateSelectorMultiSelect,
    previewer: optionsPreviewer,
    optionsKey: 'networks',
  },
  [EPredicateFields.FUNCTION]: {
    value: EPredicateFields.FUNCTION,
    defaultOperator: EPredicateOperators.IN,
    defaultValue: [],
    icon: 'assignment_ind',
    context: 'function',
    category: 'company',
    selector: PredicateSelectorMultiSelect,
    previewer: optionsPreviewer,
    optionsKey: 'functions',
  },
  [EPredicateFields.DATE]: {
    value: EPredicateFields.DATE,
    defaultOperator: EPredicateOperators.EQ,
    defaultValue: null,
    icon: 'date_start',
    context: 'date',
    category: 'calendar',
    selector: PredicateSelectorDate,
    previewer: datePreviewer,
  },
};

export type PredicateInputOwnProps = {
  input: {
    value: any,
    onChange: (newValue: any) => void,
  },
  filter: PredicateFilter;
  options: Option[];
  organisation: Organisation;
  onRemove: () => void;
  addContextToPredicatesOnChange: boolean;
  className?: string;
  scrolling?: boolean;
  buttonType?: ButtonType;
};

// Single dropdown for a predicate
export const PredicateInput = ({
  input: {
    value: predicate,
    onChange,
  },
  filter,
  options,
  organisation,
  onRemove,
  addContextToPredicatesOnChange,
  className,
  scrolling = true,
  buttonType = 'default',
}: PredicateInputOwnProps) => {
  const [isOpen, setOpen] = React.useState(!!predicate.open);
  const [selectedOptions, setSelectedOptions] = React.useState(options);

  // @ts-expect-error
  const preview = filter.previewer?.(predicate, filter, filter.getOptions ? selectedOptions : options);

  React.useEffect(() => {
    if (predicate.open) onChange(R.omit(['open'], predicate));
  }, []);

  const FilterComponent = filter.selector;

  return (
    <Dropdown
      className={`${filter.value} ${className || ''}`}
      small
      defaultOpen={!!predicate.open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);

        // Remove predicates which are "empty"
        if (onRemove && (!predicate.value || (Array.isArray(predicate.value) && predicate.value.length === 0))) {
          return onRemove();
        }
      }}
      scrolling={scrolling}
      toggle={(
        <Button type={buttonType} size="large" active={isOpen} icon={filter.icon as IconTypes}>
          <Trans i18nKey="common:predicates_filter_predicate" values={{ context: filter.context }} />
          {preview && (
            <span style={{ fontWeight: 400 }}>
              &nbsp;
              {preview}
            </span>
          )}
          {isOpen && onRemove && (
            <Icon
              type="close"
              className="PredicatesFilter__Filters__Remove"
              onClick={() => {
                onRemove();
                setOpen(false);
              }}
            />
          )}
        </Button>
      )}
      search={FilterComponent === PredicateSelectorAsyncMultiSelect}
    >
      <FilterComponent
        valueKey="id"
        labelKey="name"
        predicate={predicate}
        organisation={organisation}
        updateSelectedOptions={setSelectedOptions}
        // @ts-expect-error
        options={filter.getOptions ? selectedOptions : options}
        {...filter}
        onChange={(newValue: any) => {
          try {
            const newPredicates: any = R.merge(predicate, newValue);
            if (addContextToPredicatesOnChange) {
              // @ts-expect-error
              const opts = filter.getOptions ? selectedOptions : options;
              let context: any;
              if (Array.isArray(newPredicates.value)) {
                context = newPredicates.value.map((id: string) => {
                  return opts.find((option: any) => option.id === id);
                }).filter((option: any) => !!option);
              } else {
                context = newPredicates.value;
              }
              newPredicates.context = context;
            }
            return onChange(newPredicates);
          } catch (error) {
            console.log('debug error', error);
            throw error;
          }
        }}
      />
    </Dropdown>
  );
};

type PredicateListProps = {
  fields: {
    getAll: () => Array<Object>,
    remove: (index: number) => void,
  },
  predicateType: string,
  data: Object,
  enabledFilters: string[],
  onAddFilterClick?: () => boolean;
  onFilterSelect?: (type: `${EPredicateFields}`) => boolean;
  getState: () => StoreState,
  organisation: Organisation,
  addContextToPredicatesOnChange: boolean
};

// List of all predicate dropdowns
const PredicatesList = ({
  fields,
  predicateType,
  data,
  enabledFilters,
  getState,
  organisation,
  // @ts-expect-error
  searchResult,
  addContextToPredicatesOnChange,
  onAddFilterClick = () => true,
  onFilterSelect = () => true,
}: PredicateListProps) => {
  const { t } = useTranslation();

  const filters = R.pipe(
    // @ts-expect-error
    R.map((key) => EPredicateFilters[key]),
    R.groupBy(R.prop('category')),
  )(enabledFilters);

  const predicateTypeLabel = t(
    predicateType === ESearchPredicateTypes.MATCH_ALL
      ? 'common:utils_predicates_and'
      : 'common:utils_predicates_or'
  );

  const elems: any[] = [];

  fields.getAll().forEach((predicate: any, index: number) => {
    if (index > 0) {
      const attr = predicate.attribute;
      elems.push(
        <div
          className={`PredicatesFilter__Filters__PredicateType before-${attr}`}
          key={`${predicate.id}_type`}
        >
          {predicateTypeLabel}
        </div>
      );
    }

    const filter = EPredicateFilters[predicate.attribute];
    if (!filter || (filter.getData && !searchResult)) {
      return null;
    }

    const optionsData = filter.getData ?
      filter.getData(predicate.value, getState(), searchResult) :
      // @ts-expect-error
      filter.optionsKey && data[filter.optionsKey];

    // this fixed PD-8191 for all predicate filters types
    const options = optionsData?.filter((o: any) => !!o) || [];

    elems.push(
      <Field
        className={`order_${index}`}
        key={predicate.id}
        // @ts-expect-error
        name={`${fields.name}[${index}]`}
        filter={filter}
        options={options}
        getState={getState}
        organisation={organisation}
        onRemove={() => fields.remove(index)}
        addContextToPredicatesOnChange={addContextToPredicatesOnChange}
        component={PredicateInput}
      />
    );
  });

  const existingFields = fields.getAll().map((predicate: any) => predicate.attribute);

  return (
    <>
      {elems}

      <Dropdown
        small
        onClick={onAddFilterClick}
        toggle={(
          <Button type="transparent" iconRight="add" size="large">
            <Trans i18nKey="common:predicates_filter_add_filter" />
          </Button>
        )}
      >
        {R.values(R.mapObjIndexed((category, key) => {
          // @ts-expect-error
          const handleAddPredicate = (filter) => fields.push({
            id: createTempId(),
            open: true,
            attribute: filter.value,
            comparison: filter.defaultOperator,
            value: filter.defaultValue,
          });

          return (
            <React.Fragment key={key}>
              <Dropdown.Header>
                <Trans
                  i18nKey="common:predicates_filter_category"
                  values={{ context: key }}
                />
              </Dropdown.Header>
              {category.map((filter) => (
                <Dropdown.Item
                  className={`${filter.value}_option`}
                  key={filter.value}
                  onClick={() => onFilterSelect(filter.value) && handleAddPredicate(filter)}
                  disabled={existingFields.includes(filter.value)}
                >
                  <Icon type={filter.icon} />
                  <Trans
                    i18nKey="common:predicates_filter_predicate"
                    values={{ context: filter.context }}
                  />
                </Dropdown.Item>
              ))}
            </React.Fragment>
          );
        }, filters))}
      </Dropdown>
    </>
  );
};

const defaultFilters = [
  EPredicateFields.USER,
  EPredicateFields.LANGUAGE,
  EPredicateFields.DAYS_IN_SERVICE,
  EPredicateFields.NETWORK,
  EPredicateFields.FUNCTION,
  EPredicateFields.DAYS_BEFORE_OUT_OF_SERVICE,
];

type AudienceModalProps = {
  children: React.ReactNode;
};

const getStateGetter = (state: StoreState, props: any) => {
  // this getInjectedState allows us to create a fake redux state so we
  // can provide custom data to the component instead of relying on redux
  // state
  if (props.getInjectedState) {
    return () => props.getInjectedState(state);
  }
  // previous solution to problem explained above, but it forces us to extract
  // the redux state in the parent components and have it consumed by
  // props.getState
  if (props.getState) {
    return props.getState;
  }

  // this just returns the state, so the AudienceInput will just read the
  // actual redux data
  return () => state;
};

const mapStateToProps = (state: StoreState, props: any) => {
  const getState = getStateGetter(state, props);
  const possiblyInjectedReduxState = getState();
  const locales = possiblyInjectedReduxState.language.translations;
  return {
    organisation: organisationSelector.selected(possiblyInjectedReduxState),
    networks: organisationSelector.networks(possiblyInjectedReduxState),
    functions: organisationSelector.functions(possiblyInjectedReduxState),
    languageLocales: locales.map((value: any) => {
      return { ...value, id: value.locale };
    }),
    getState
  };
};

const mapDispatchToProps = {};

const predicatesFilterConnector = connect(mapStateToProps, mapDispatchToProps);

type PredicatesFilterConnectedProps = ConnectedProps<typeof predicatesFilterConnector>;

export type PredicatesFilterOwnProps = {
  onAddFilterClick?: () => boolean;
  onFilterSelect?: (type: `${EPredicateFields}`) => boolean;
  formValues?: {
    predicate_type: ESearchPredicateTypes;
    filters: {
      networks: Network[];
      functions: OrganisationFunction[];
      users: SimpleUser[];
    };
  };
  getSearchResults?: (result: Record<string, unknown>) => void;
  description?: any;
  enabledFilters?: string[];
  tooltips: {
    audience?: string;
  };
  addContextToPredicatesOnChange?: boolean;
};

type PredicateFilterApiResponse = ListApiResponse<
  Record<string, unknown>[],
  undefined,
  { pagination: { total_count: number } }
>;

type Props = WrappedFieldProps & PredicatesFilterConnectedProps & PredicatesFilterOwnProps;

export type PredicatesFilterValue = {
  search: string;
  predicate_type: ESearchPredicateTypes;
  predicates: Predicate[];
};

const PredicatesFilterComponent = ({
  input: {
    value: audience,
    name,
  },
  organisation,
  onAddFilterClick,
  onFilterSelect,
  getSearchResults,
  description,
  enabledFilters = defaultFilters,
  getState,
  tooltips = {},
  addContextToPredicatesOnChange = false,
  ...data
}: Props) => {
  const { t } = useTranslation();
  const [searchResult, setSearchResult] = React.useState<PredicateFilterApiResponse | null>(null);
  const [showAudienceModal, setShowAudienceModal] = React.useState(false);

  const columns = [
    { size: 35 },
    { label: t('common:predicates_filter_column_name_label'), className: 'Table__Cell__Title' },
    { label: t('common:predicates_filter_column_communities_label') },
    { label: t('common:predicates_filter_column_functions_label') },
  ];

  const onFetch = (offset: number | undefined, filter: PredicatesFilterValue, limit = 10) => {
    const query = Api.utils.toQuery({
      offset,
      limit,
      include: ['networks', 'functions'],
      q: filter.search,
    });

    return Api.post(`/v1/organisations/${organisation.id}/users/search?${query}`, {
      predicate_type: filter.predicate_type,
      predicates: predicatesToPayload(filter.predicates),
    });
  };

  // @ts-expect-error
  const AudienceModal = React.memo(({ children, show }: AudienceModalProps) => {
    const [audienceSearch, setAudienceSearch] = React.useState<string | null>(null);
    const [scrollContainer, setScrollContainer] = React.useState<HTMLDivElement | void>();

    const handleOnShow = () => {
      if (document) {
        // @ts-expect-error
        setScrollContainer(document.querySelector('.AudienceModal .modal-body'));
      }
    };

    return (
      <Modal
        size="large"
        title={t('common:predicates_filter_who_will_receive')}
        show={show}
        onShow={handleOnShow}
        // @ts-expect-error
        onHide={() => setShowAudienceModal(false)}
        className="AudienceModal"
        header={(
          // @ts-expect-error
          <SearchBar defaultValue={audienceSearch} onSearch={setAudienceSearch} />
        )}
        content={(
          <AsyncTable
            // @ts-expect-error
            scrollContainer={scrollContainer}
            columns={columns}
            data={{
              onFetch,
              limit: 25,
              cache: searchResult && {
                items: searchResult.data,
                pagination: searchResult.meta.pagination,
              },
              filter: {
                ...audience,
                search: audienceSearch,
              },
            }}
            // @ts-expect-error
            renderRow={PredicatesUserItem}
            rowProps={{
              maxShownItems: 2,
            }}
          />
        )}
      >
        {children}
      </Modal>
    );
  });

  return (
    <div className="PredicatesFilter">
      {/* @ts-expect-error */}
      <AudienceModal show={showAudienceModal} />
      <div className="PredicatesFilter__Column PredicatesFilter__Column--size-large">
        <Label text={t('common:predicates_filter_who_will_receive')} description={tooltips.audience} />

        <div className="PredicatesFilter__Filters">
          <Field
            name={`${name}.predicate_type`}
            // @ts-expect-error
            component={({ input: { value, onChange } }) => (
              <Dropdown
                small
                toggle={(
                  <Button size="large"><Trans i18nKey="common:predicates_filter_type" values={{ context: value }} /></Button>
                )}
              >
                {predicateTypes.map((type) => (
                  <Dropdown.Item key={type} onClick={() => onChange(type)} selected={value === type}>
                    <Trans i18nKey="common:predicates_filter_type" values={{ context: type }} />
                  </Dropdown.Item>
                ))}
              </Dropdown>
            )}
          />
          {/* @ts-ignore */}
          <FieldArray
            name={`${name}.predicates`}
            // @ts-ignore
            predicateType={audience.predicate_type}
            data={data}
            enabledFilters={enabledFilters}
            organisation={organisation}
            getState={getState}
            searchResult={searchResult}
            onAddFilterClick={onAddFilterClick}
            onFilterSelect={onFilterSelect}
            addContextToPredicatesOnChange={addContextToPredicatesOnChange}
            component={PredicatesList}
          />
        </div>

        {searchResult && (
          <h3>
            <Trans
              i18nKey="common:predicates_filter_search_result"
              values={{ count: searchResult.meta.pagination.total_count }}
              components={[<b />]}
            />
            &nbsp;·&nbsp;
            <a onClick={() => setShowAudienceModal(true)}>
              <Trans i18nKey="common:predicates_filter_view_all" />
            </a>
          </h3>
        )}

        {description && <div className="PredicatesFilter__Description">{description}</div>}
        {/* @ts-expect-error */}
        <AsyncTable
          columns={columns}
          data={{
            onFetch,
            onPostFetch: (result: PredicateFilterApiResponse) => {
              setSearchResult(result);
              getSearchResults?.(result);
            },
            filter: audience,
          }}
          renderRow={PredicatesUserItem}
          ShowMoreComponent={({ count }: { count: number }) => (
            <div className="PredicatesFilter__ShowMore" onClick={() => setShowAudienceModal(true)}>
              <Trans i18nKey="common:predicates_filter_view_all_employees" values={{ count }} />
            </div>
          )}
          placeholder={(
            <div className="Placeholder">
              <div className="Placeholder__Inner">
                <Trans i18nKey="common:predicates_filter_search_result_placeholder" />
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export const PredicatesFilter = predicatesFilterConnector(PredicatesFilterComponent);

import React, {
  useState,
  useRef,
  useEffect,
  ReactElement,
  ChangeEvent,
  KeyboardEvent,
  ComponentType,
  MouseEvent,
} from 'react';
import EditorUtils from '@draft-js-plugins/utils';
import { EditorState } from 'draft-js';
import { Icon } from '@common/components/icon';
import { combineClassNames } from '@utils/combineClassNames';
import URLUtils from '../utils/url-utils';
import { AnchorPluginTheme } from '../theme';

export interface OverrideContentProps {
  getEditorState: () => EditorState;
  setEditorState: (editorState: EditorState) => void;
  onOverrideContent: (content: ComponentType<unknown> | undefined) => void;
}

interface AddLinkFormParams extends OverrideContentProps {
  validateUrl?(url: string): boolean;
  theme: AnchorPluginTheme;
  placeholder?: string;
  previousValue?: string;
  onRemoveLinkAtSelection(): void;
}

const AddLinkForm = ({ previousValue = '', onRemoveLinkAtSelection, ...props }: AddLinkFormParams): ReactElement => {
  const [value, setValue] = useState(previousValue);

  const isUrl = (urlValue: string): boolean => {
    if (props.validateUrl) {
      return props.validateUrl(urlValue);
    }

    return URLUtils.isUrl(urlValue);
  };

  const [isValid, setIsValid] = useState(true);

  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    input.current?.focus();
  }, []);

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    const newValue = event.target.value;
    setValue(newValue);
    if (!isValid) {
      setIsValid(isUrl(URLUtils.normalizeUrl(newValue)));
    }
  };

  const onClose = (): void => props.onOverrideContent(undefined);

  const submit = (): void => {
    const { getEditorState, setEditorState } = props;

    let url = value;

    if (!URLUtils.isMail(URLUtils.normaliseMail(url))) {
      url = URLUtils.normalizeUrl(url);
      if (!isUrl(url) || !url) {
        return setIsValid(false);
      }
    } else {
      url = URLUtils.normaliseMail(url);
    }
    setEditorState(EditorUtils.createLinkAtSelection(getEditorState(), url));
    input.current!.blur();
    onClose();
  };

  const onKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submit();
    } else if (event.key === 'Escape') {
      event.preventDefault();
      onClose();
    }
  };

  const preventClosing = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleRemoveLink = (e: MouseEvent) => {
    preventClosing(e);
    onRemoveLinkAtSelection();
    onClose();
  };

  const handleUpdateLink = (e: MouseEvent) => {
    preventClosing(e);
    submit();
  };

  const { placeholder } = props;

  const fullInputWrapperClassName = combineClassNames('DraftJSAnchor__DefaultFormInputWrapper', {
    'DraftJSAnchor__DefaultFormInputWrapper--invalid': !isValid,
  });

  return (
    <div className="DraftJSAnchor__DefaultForm">
      {previousValue && (
        <Icon
          type="delete__filled"
          role="button"
          onMouseDown={preventClosing}
          onClick={handleRemoveLink}
        />
      )}
      <div className={fullInputWrapperClassName}>
        <input
          onBlur={onClose}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onMouseDown={preventClosing}
          onClick={preventClosing}
          placeholder={placeholder}
          ref={input}
          type="text"
          value={value}
          id="DraftJSAnchor__DefaultFormInput"
        />
        <Icon
          type="check"
          onMouseDown={preventClosing}
          onClick={handleUpdateLink}
          role="button"
        />
      </div>
    </div>
  );
};

AddLinkForm.defaultProps = {
  placeholder: 'Enter a URL and press enter',
};

export default AddLinkForm;
